import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text } from "../components/Core"

const SearchResultsPage = ({ data, location }) => {
  const { siteMetadata } = data.site

  const metaDescription = `Search results for ${data.site.siteMetadata.fullPracticeName}`

  if (
    location.state &&
    location.state.suggestions &&
    location.state.searchInput &&
    location.state.searchTime
  ) {
    const results = location.state.suggestions
    const input = location.state.searchInput
    const { searchTime } = location.state
    const searchResults = Object.keys(results).map(key => {
      if (key !== "key") {
        const heading = results[key].searchHeading
        const paragraph = results[key].metaDescription
        let url = results[key].url || results[key].title
        // url = url.toLowerCase();
        if (url.charAt(0) !== "/") url = `/${url}`

        return (
          <div key={url} className="single-result">
            <Link to={url}>
              <Text as="h6" className="modified-h6" text={heading} />
            </Link>
            <Text as="p" text={paragraph} />
            <Link to={url}>{`${siteMetadata.siteUrl}${url}`}</Link>
          </div>
        )
      }
    })

    const resultsLength = Object.keys(results).length

    return (
      <Layout language="en">
        <SEO
          title="Search Results"
          description={metaDescription}
          robots="noindex"
          lang="en"
        />
        <section className="com-content search-results-page">
          <div className="container">
            <div className="columns">
              <div className="column" />
              <div className="column is-20">
                <h2>Search Results</h2>
                <h6>
                  {resultsLength} result{resultsLength === 1 ? "" : "s"} for "
                  {input}" ({searchTime} seconds)
                </h6>
                {resultsLength > 0 && (
                  <div className="search-results-wrapper">{searchResults}</div>
                )}
              </div>
              <div className="column" />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
  return (
    <Layout language="en">
      <SEO
        title="Search Results"
        description={metaDescription}
        robots="noindex"
      />
      <section className="com-content">
        <div className="container">
          <div className="columns">
            <div className="column" />
            <div className="column is-20">
              <h2>Search Results</h2>
              <h6>0 results found</h6>
            </div>
            <div className="column" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query searchQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        fullPracticeName
      }
    }
  }
`

export default SearchResultsPage
